const HowToPlay = () => {
  return (
    <div className="w-full h-[152vw] max-h-[2880px] lg:container flex flex-col self-center justify-end">
      <div className=" lg:container flex self-center justify-center">
        <div className="self-center flex flex-col justify-end w-[82vw]">
          {/* BLOCK 1 */}
          <div className="flex items-center">
            <div className="flex flex-col flex-1 gap-8">
              <div className="flex flex-col gap-3">
                <p className="font-paytone 2xl:text-[56px] xl:text-[48px] lg:text-[40px] text-[#813A23]">
                  Merge-to-earn
                </p>
                <p className="font-dosis 2xl:text-2xl xl:text-xl lg:text-lg md:text-[14px] text-[10px] text-[#813A2380] md:mr-0 mr-[4px]">
                  Merge your fishes to level up and unlock bigger rewards. The
                  <br />
                  higher you go, the more you earn!
                </p>
              </div>
              <a
                href={"https://t.co/NrnleVfVbr"}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-row gap-3 bg-[#31BB16] 2xl:px-8 xl:px-6 lg:px-4 lg:py-2 py-1 px-3 h-fit rounded-3xl border-[4px] border-[#338123] w-fit  transition duration-300 transform hover:scale-105 animate-fadeIn"
              >
                <p className="font-paytone 2xl:text-2xl xl:text-xl lg:text-lg md:text-base text-[10px] drop-shadow-custom text-white uppercase tracking-wide">
                  Merge now
                </p>
              </a>
            </div>

            <div className="flex flex-1 lg:gap-8 md:gap-6 gap-4 justify-end">
              <img
                src={"/merge_1.png"}
                alt="merge-to-earn"
                width={100}
                height={100}
                className="w-[42%] h-fit object-contain transition duration-300 transform hover:scale-105 cursor-pointer"
              />
              <img
                src={"/merge_2.png"}
                alt="merge-to-earn"
                width={100}
                height={100}
                className="w-[42%] h-fit object-contain  transition duration-300 transform hover:scale-105 cursor-pointer"
              />

              <img
                src={"/lifebuoy.png"}
                alt="lifebuoy"
                width={100}
                height={100}
                className="w-1/5 h-fit object-contain -mt-[14%] -ml-[22%] z-50"
              />
            </div>
          </div>

          {/* BLOCK 2 */}
          <div className="flex items-center mt-[16%]">
            <div className="flex flex-1 lg:gap-8 md:gap-6 gap-4 relative">
              <img
                src={"/beer.png"}
                alt="beer"
                width={100}
                height={140}
                className="w-1/3 h-fit object-contain absolute -left-[14%] -top-[25%] z-20"
              />

              <img
                src={"/invite_1.png"}
                alt="invite-to-earn"
                width={100}
                height={100}
                className="w-[42%] h-fit object-contain transition duration-300 transform hover:scale-105 cursor-pointer"
              />

              <img
                src={"/invite_2.png"}
                alt="invite-to-earn"
                width={100}
                height={100}
                className="w-[42%] h-fit object-contain transition duration-300 transform hover:scale-105 cursor-pointer"
              />
            </div>

            <div className="flex flex-1 items-end justify-end">
              <div className="flex flex-col gap-8 lg:mr-[14%] mr-0">
                <div className="flex flex-col gap-3">
                  <p className="font-paytone 2xl:text-[56px] xl:text-[48px] lg:text-[40px] text-[#813A23]">
                    Invite-to-earn
                  </p>
                  <p className="font-dosis 2xl:text-2xl xl:text-xl lg:text-lg md:text-[14px] text-[10px] text-[#813A2380]">
                    Invite your friends and rise to the top of the leaderboard.
                    <br />
                    Enjoy exclusive rewards delivered straight to your pocket!
                  </p>
                </div>
                <a
                  href={"https://t.co/NrnleVfVbr"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row gap-3 bg-[#31BB16] 2xl:px-8 xl:px-6 lg:px-4 lg:py-2 py-1 px-3 h-fit rounded-3xl border-[4px] border-[#338123] w-fit  transition duration-300 transform hover:scale-105 animate-fadeIn"
                >
                  <p className="font-paytone 2xl:text-2xl xl:text-xl lg:text-lg md:text-base text-[10px] drop-shadow-custom text-white uppercase tracking-wide">
                    Invite now
                  </p>
                </a>
              </div>
            </div>
          </div>

          {/* BLOCK 3 */}
          <div className="flex items-center mt-[16%]">
            <div className="flex flex-col flex-1 gap-8">
              <div className="flex flex-col gap-3">
                <p className="font-paytone 2xl:text-[56px] xl:text-[48px] lg:text-[40px] text-[#813A23]">
                  Play-to-airdrop
                </p>
                <p className="font-dosis 2xl:text-2xl xl:text-xl lg:text-lg md:text-[14px] text-[10px] text-[#813A2380]">
                  Embark on an exciting adventure and get ready for an
                  <br />
                  exclusive airdrop from One-fish treasure!
                </p>
              </div>

              <a
                href={"https://t.co/NrnleVfVbr"}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-row gap-3 bg-[#31BB16] 2xl:px-8 xl:px-6 lg:px-4 lg:py-2 py-1 px-3 h-fit rounded-3xl border-[4px] border-[#338123] w-fit  transition duration-300 transform hover:scale-105 animate-fadeIn"
              >
                <p className="font-paytone 2xl:text-2xl xl:text-xl lg:text-lg md:text-base text-[10px] drop-shadow-custom text-white uppercase tracking-wide">
                  Play now
                </p>
              </a>
            </div>

            <div className="flex flex-1 lg:gap-8 md:gap-6 gap-4 justify-end">
              <img
                src={"/airdrop_1.png"}
                alt="merge-to-earn"
                width={100}
                height={100}
                className="w-[42%] h-fit object-contain transition duration-300 transform hover:scale-105 cursor-pointer"
              />
              <img
                src={"/airdrop_2.png"}
                alt="merge-to-earn"
                width={100}
                height={100}
                className="w-[42%] h-fit object-contain transition duration-300 transform hover:scale-105 cursor-pointer"
              />

              <img
                src={"/token.png"}
                alt="lifebuoy"
                width={100}
                height={100}
                className="w-1/6 h-fit object-contain -mt-[10%] -ml-[18%] z-50"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPlay;
