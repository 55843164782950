import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import { useState } from "react";

export const mainMenus = [
  {
    name: "Home",
    href: "/",
  },
  // {
  //   name: "Pitchdesk",
  //   href: "/pitch-desk",
  // },
];

export const IconMenu = ({
  className = "text-black",
}: {
  className?: string;
}) => (
  <svg
    className={className}
    viewBox="0 0 34 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="34" height="2.61538" rx="1.30769" fill="currentColor" />
    <rect
      y="10.4617"
      width="34"
      height="2.61538"
      rx="1.30769"
      fill="currentColor"
    />
  </svg>
);

const MobileMainMenu = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <DropdownMenu.Root open={open}>
      <DropdownMenu.Trigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        <button className="outline-none">
          <IconMenu className="w-6 cursor-pointer text-white hover:text-[#FFCE52] mds:w-8" />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          onInteractOutside={() => {
            setOpen(false);
          }}
          className={clsx(
            "z-50 flex w-[200px] flex-col space-y-3 rounded-md border bg-white py-3 shadow-lg outline-none ring-0"
          )}
          sideOffset={5}
          collisionPadding={{
            right: 5,
          }}
          align={"end"}
        >
          {mainMenus.map((menu) => (
            <div key={menu.name} className="outline-none">
              <a
                href={menu.href}
                className="block px-5 font-dosis text-lg tracking-wide font-semibold text-black hover:text-[#FFCE52] focus:text-[#FFCE52] mds:text-sm xl:text-base"
              >
                {menu.name}
              </a>
            </div>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default MobileMainMenu;
