import dayjs from "dayjs";

const Roadmap = () => {
  const isQ42024 =
    dayjs().isAfter("2024-09-30") && dayjs().isBefore("2024-12-31");

  const isQ12025 =
    dayjs().isAfter("2025-01-01") && dayjs().isBefore("2025-03-31");

  const isQ42025 =
    dayjs().isAfter("2025-09-30") && dayjs().isBefore("2025-12-31");

  return (
    <div className="w-full h-[82vw] max-h-[1574.4px] lg:container self-center flex flex-col items-center justify-center">
      <div className="lg:container self-center flex items-center justify-center">
        <div className="flex flex-col justify-center items-center w-[82vw]">
          <p className="font-paytone 2xl:text-[56px] xl:text-[48px] lg:text-[40px] text-[28px] lg:font-outline-3 md:font-outline-2 font-outline-1 text-[#FFCE52] tracking-tight mb-[5%]">
            Road Map
          </p>
          {/* BLOCK 1 */}
          <div className="flex w-full">
            <div className="flex w-full">
              <div className="flex-1">
                <img
                  src={"/cat_1.png"}
                  alt="cat"
                  width={172}
                  height={172}
                  className="w-1/2 object-contain h-fit"
                />
              </div>

              <div className="flex-1">
                <p className="xl:text-[32px] lg:text-[24px] md:text-[18px] text-xs font-paytone text-white">
                  2024 Q4
                </p>
                <p className="xl:text-2xl lg:text-xl md:text-base text-[12px] font-dosis font-bold xl:mt-4 lg:mt-3 md:mt-2 mt-1 text-white">
                  Optimization
                </p>

                <p className="xl:text-xl lg:text-lg md:text-xs text-[10px] font-dosis font-medium xl:mt-3 lg:mt-2 mt-1 text-white">
                  • Squash those pesky bugs!
                </p>
                <p className="xl:text-xl lg:text-lg md:text-xs text-[10px] font-dosis font-medium text-white">
                  • Unveil new and expanded
                  <br />
                  features for a richer gaming
                  <br />
                  experience.
                </p>
              </div>

              <div className="flex-1">
                <img
                  src={"/cat_2.png"}
                  alt="cat"
                  width={172}
                  height={172}
                  className="w-1/2 object-contain h-fit"
                />
              </div>

              <div className="flex-1">
                <p className="xl:text-[32px] lg:text-[24px] md:text-[18px] text-xs font-paytone text-white">
                  2025 Q4
                </p>
                <p className="xl:text-2xl lg:text-xl md:text-base text-[12px] font-dosis font-bold xl:mt-4 lg:mt-3 md:mt-2 mt-1 text-white">
                  Innovation and Expansion
                </p>

                <p className="xl:text-xl lg:text-lg md:text-xs text-[10px] font-dosis font-medium xl:mt-3 lg:mt-2 mt-1 text-white">
                  • Boost user engagement and
                  <br />
                  explore new horizons in the
                  <br />
                  Purrate World!
                </p>
              </div>
            </div>
          </div>

          {/* BLOCK 2 */}
          <div className="w-full my-[5%] flex justify-center relative">
            <img
              src={"/rope.svg"}
              alt="rope"
              width={1000}
              height={12}
              className="w-full object-contain h-fit"
            />

            <div className="absolute w-full flex self-center">
              <div className="flex-1">
                <img
                  src={"/checked.svg"}
                  alt="checked"
                  width={60}
                  height={60}
                  className="xl:w-[60px] xl:h-[60px] lg:w-[50px] lg:h-[50px] md:w-[40px] md:h-[40px] w-[30px] h-[30px]"
                />
              </div>
              <div className="flex-1">
                <img
                  src={isQ42024 ? "/checked.svg" : "/uncheck.svg"}
                  alt="checked"
                  width={60}
                  height={60}
                  className="xl:w-[60px] xl:h-[60px] lg:w-[50px] lg:h-[50px] md:w-[40px] md:h-[40px] w-[30px] h-[30px]"
                />
              </div>
              <div className="flex-1">
                <img
                  src={isQ12025 ? "/checked.svg" : "/uncheck.svg"}
                  alt="checked"
                  width={60}
                  height={60}
                  className="xl:w-[60px] xl:h-[60px] lg:w-[50px] lg:h-[50px] md:w-[40px] md:h-[40px] w-[30px] h-[30px]"
                />
              </div>
              <div className="flex-1">
                <img
                  src={isQ42025 ? "/checked.svg" : "/uncheck.svg"}
                  alt="checked"
                  width={60}
                  height={60}
                  className="xl:w-[60px] xl:h-[60px] lg:w-[50px] lg:h-[50px] md:w-[40px] md:h-[40px] w-[30px] h-[30px]"
                />
              </div>
            </div>
          </div>

          {/* BLOCK 3 */}
          <div className="flex w-full">
            <div className="flex w-full">
              <div className="flex-1">
                <p className="xl:text-[32px] lg:text-[24px] md:text-[18px] text-xs font-paytone text-white">
                  2024 Q3
                </p>
                <p className="xl:text-2xl lg:text-xl md:text-base text-[12px] font-dosis font-bold xl:mt-4 lg:mt-3 md:mt-2 mt-1 text-white">
                  Launch Phase
                </p>

                <p className="xl:text-xl lg:text-lg md:text-xs text-[10px] font-dosis font-medium xl:mt-3 lg:mt-2 mt-1 text-white">
                  • Set sail with the Purrate King
                  <br />
                  gaming center on Telegram!
                </p>
                <p className="xl:text-xl lg:text-lg md:text-xs text-[10px] font-dosis font-medium text-white">
                  • Reward early users and
                  <br />
                  contributors with an exciting
                  <br />
                  token airdrop.
                </p>
              </div>

              <div className="flex-1">
                <img
                  src={"/cat_3.png"}
                  alt="cat"
                  width={172}
                  height={172}
                  className="w-1/2 object-contain h-fit"
                />
              </div>

              <div className="flex-1">
                <p className="xl:text-[32px] lg:text-[24px] md:text-[18px] text-xs font-paytone text-white">
                  2025 Q1
                </p>
                <p className="xl:text-2xl lg:text-xl md:text-base text-[12px] font-dosis font-bold xl:mt-4 lg:mt-3 md:mt-2 mt-1 text-white">
                  Purrate World
                </p>

                <p className="xl:text-xl lg:text-lg md:text-xs text-[10px] font-dosis font-medium xl:mt-3 lg:mt-2 mt-1 text-white">
                  • Supercharge the platform with
                  <br />
                  enhanced capabilities.
                </p>
              </div>

              <div className="flex-1">
                <img
                  src={"/cat_4.png"}
                  alt="cat"
                  width={172}
                  height={172}
                  className="w-1/2 object-contain h-fit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
