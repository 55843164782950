const Distribution = () => {
  return (
    <div className="w-full h-[52vw] max-h-[998px] lg:container self-center flex flex-col items-center justify-center ">
      <p className="font-paytone 2xl:text-[56px] xl:text-[48px] lg:text-[40px] lg:font-outline-3 md:font-outline-2 font-outline-1 text-[#FFCE52] tracking-tight mt-[2%]">
        $1FISH Token Distribution
      </p>

      <img
        src={"/distribution.svg"}
        alt="distribution"
        width={686}
        height={552}
        className="w-1/2 object-contain h-fit mt-[2%]"
      />
    </div>
  );
};

export default Distribution;
