const Introduction = () => {
  return (
    <div className="h-[52vw] max-h-[998px] w-full flex justify-center">
      <div className="flex lg:container h-full items-center justify-center">
        <div className="w-[82vw] flex flex-col xl:gap-9 lg:gap-8 md:gap-7 gap-6">
          <img
            src={"/intro.svg"}
            alt="intro"
            width={100}
            height={100}
            className="w-[50vw] max-w-[960px]"
          />

          <a
            href={"https://t.co/NrnleVfVbr"}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row gap-3 bg-[#31BB16] 2xl:px-8 xl:px-6 lg:px-4 lg:py-2 py-1 px-3 h-fit rounded-3xl border-[4px] border-[#338123] w-fit  transition duration-300 transform hover:scale-105 animate-fadeIn"
          >
            <p className="font-paytone 2xl:text-2xl xl:text-xl lg:text-lg md:text-base text-[10px] drop-shadow-custom text-white uppercase tracking-wide">
              Join the adventure now
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
