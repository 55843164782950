const ComingSoon = () => {
  return (
    <div className="flex flex-1">
      <img
        src="/coming_soon.jpg"
        width={1000}
        height={1000}
        className="bg-cover bg-no-repeat w-full h-full"
        alt="coming-soon"
      />
    </div>
  );
};

export default ComingSoon;
