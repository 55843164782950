const Footer = () => {
  return (
    <footer className="bg-[#F9BF3D] flex justify-center absolute bottom-0 w-full">
      <div className="lg:container flex justify-center">
        <div className="flex flex-row w-[85vw] justify-between items-center py-2">
          <div className="flex xl:gap-16 lg:gap-14 md:gap-12 gap-8 items-center">
            <p className="font-paytone xl:text-[36px] lg:text-[30px] md:text-[24px] text-[18px] text-[#813A23]">
              Welcome Aboard!
            </p>

            <a
              href={
                "https://drive.google.com/drive/folders/1bvMUxpLi1HafI8bsyk4g-xP39YeVE8pd"
              }
              target="_blank"
              rel="noopener noreferrer"
              className="lg:text-lg md:text-base text-xs text-[#813A23] font-dosis font-semibold -mb-2"
            >
              Media Kit
            </a>
          </div>

          <div className="flex flex-row items-center gap-3">
            <a
              href={"https://t.me/purratekingAnn"}
              target="_blank"
              rel="noopener noreferrer"
              className="transition duration-300 transform hover:scale-105"
            >
              <img
                src={"/telegram_icon.svg"}
                alt="telegram"
                width={32}
                height={32}
              />
            </a>
            <a
              href={"https://x.com/purrateking"}
              target="_blank"
              rel="noopener noreferrer"
              className="transition duration-300 transform hover:scale-105"
            >
              <img
                src={"/twitter_icon.svg"}
                alt="telegram"
                width={32}
                height={32}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
