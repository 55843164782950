import MobileMainMenu from "./MobileMainMenu";

const Header = () => {
  return (
    <header className="bg-[#152949] flex justify-center absolute w-full z-10">
      <div className="flex flex-row container justify-between items-center w-[85vw] lg:py-4 md:py-3 py-2">
        <div className="flex flex-row items-center gap-10">
          <a href={"/"}>
            <img
              src={"/logo.svg"}
              alt="logo"
              className="md:w-[86px] md:h-[60px] w-[52px] h-[26px]"
            />
          </a>

          <ul className="flex gap-6">
            <li key={"home"} className="text-[#FFCE52E5] font-extrabold">
              <a href={"/"}>
                <p className="font-dosis text-sm md:flex hidden">Home</p>
              </a>
            </li>

            {/* <li
              key={"pitch-desk"}
              className="text-white hover:text-[#FFCE52E5] font-extrabold"
            >
              <a href={"/pitch-desk"}>
                <p className="font-dosis text-sm md:flex hidden">Pitchdesk</p>
              </a>
            </li> */}
          </ul>
        </div>

        <div className="lg:gap-4 gap-2 h-fit md:flex hidden">
          <a
            href={"https://t.me/purratekingAnn"}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row gap-3 bg-[#FFCE52] px-4 py-2 h-fit rounded-3xl border-[4px] border-[#813A23]  transition duration-300 transform hover:scale-105"
          >
            <img
              src={"/telegram.svg"}
              width={24}
              height={24}
              className="xl:w-6 xl:h-6"
              alt="telegram"
            />

            <p className="font-paytone text-base drop-shadow-custom text-[#813A23] uppercase xl:flex hidden">
              Announcements
            </p>
          </a>

          <a
            href={"https://x.com/purrateking"}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row gap-3 bg-[#FFCE52] px-4 py-2 h-fit rounded-3xl border-[4px] border-[#813A23]  transition duration-300 transform hover:scale-105"
          >
            <img
              src={"/twitter.svg"}
              width={24}
              height={24}
              className="xl:w-6 xl:h-6"
              alt="telegram"
            />

            <p className="font-paytone text-base drop-shadow-custom text-[#813A23] uppercase  xl:flex hidden">
              Twitter
            </p>
          </a>

          <a
            href={"https://t.co/NrnleVfVbr"}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row gap-3 bg-[#31BB16] xl:px-12 lg:px-10 px-8 py-2 h-fit rounded-3xl border-[4px] border-[#338123] w-fit transition duration-300 transform hover:scale-105"
          >
            <p className="font-paytone md:text-base text-xs drop-shadow-custom text-white uppercase  tracking-wide">
              Play Game
            </p>
          </a>
        </div>

        <div className="flex md:hidden">
          <MobileMainMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
