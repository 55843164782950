import { useState, useRef, useEffect } from "react";

const About = () => {
  const [playingSource, setPlayingSource] = useState("/vid_1.mp4");
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowVideo(true);
        }
      },
      {
        root: null, // viewport
        rootMargin: "0px", // no margin
        threshold: 0.5, // 50% of target visible
      }
    );

    const currentTarget = targetRef.current;

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    // Clean up the observer
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, []);

  return (
    <div className="w-full h-[52vw] max-h-[998px] lg:container self-center flex flex-col items-center justify-center ">
      <p className="font-paytone 2xl:text-[56px] xl:text-[48px] lg:text-[40px]  text-[28px] lg:font-outline-3 md:font-outline-2 font-outline-1 text-[#FFCE52] tracking-tight 2xl:-mb-[14%] xl:-mb-[16%] lg:-mb-[18%] md:-mb-[16%] -mb-[14%]">
        About Purrate King
      </p>
      <div
        className="flex h-1/2 relative justify-center items-center -bottom-[30%]"
        ref={targetRef}
      >
        {showVideo ? (
          <video
            ref={videoRef}
            src={playingSource}
            controls
            autoPlay
            onEnded={(e) => {
              setPlayingSource(
                playingSource === "/vid_1.mp4" ? "/vid_2.mp4" : "/vid_1.mp4"
              );
            }}
            className="max-w-[106%] h-full object-cover border-[4px] border-[#813A23] rounded-[20px] hover:cursor-pointer"
          />
        ) : (
          <img
            src={"/background_video.svg"}
            alt="video"
            width={100}
            height={100}
            className="w-full h-full object-cover border-[4px] border-[#813A23] rounded-[20px] hover:cursor-pointer"
          />
        )}

        {!showVideo && (
          <img
            src={"/play.svg"}
            alt="fish"
            width={80}
            height={80}
            className="absolute self-center hover:cursor-pointer transition duration-300 transform hover:scale-105 xl:w-[80px] xl:h-[80px] lg:w-[68px] lg:h-[68px] md:w-[56px] md:h-[56px] w-[40px] h-[40px]"
            onClick={() => setShowVideo(!showVideo)}
          />
        )}
        <img
          src={"/fish.png"}
          alt="fish"
          width={100}
          height={100}
          className="absolute -bottom-[10%] -left-[20%] w-1/3"
        />
        <img
          src={"/treasure.png"}
          alt="treasure"
          width={100}
          height={100}
          className="absolute -bottom-[20%] -right-[20%] w-1/3"
        />
      </div>
    </div>
  );
};

export default About;
