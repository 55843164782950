import ComingSoon from "./components/ComingSoon";
import HomePage from "./components/Home";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/pitch-desk" element={<ComingSoon />} />
      {/* Add more routes as needed */}
    </Routes>
  );
}

export default App;
