import Header from "../Header";
import Introduction from "../Introduction";
import About from "../About";
import HowToPlay from "../HowToPlay";
import Roadmap from "../Roadmap";
import Distribution from "../Distribution";
import Footer from "../Footer";
import "tippy.js/dist/tippy.css";

function HomePage() {
  return (
    <div className="relative max-h-[8064px] h-[420vw] w-[100vw] snap-none overflow-auto scroll-auto whitespace-nowrap mds:overflow-x-hidden mds:scroll-smooth flex justify-center">
      <main
        id="tf-main"
        className="flex flex-col relative bg-contain bg-no-repeat w-full h-full aspect-none max-w-screen-3xl"
        style={{ backgroundImage: "url('/background_1.png')" }}
      >
        <Header />
        <img
          src={"/ship.png"}
          alt="intro"
          width={100}
          height={100}
          className="w-full object-contain absolute z-1"
        />
        <Introduction />
        <About />
        <HowToPlay />
        <Roadmap />
        <Distribution />
        <Footer />
      </main>
    </div>
  );
}

export default HomePage;
